<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <TextInput
                  v-model="form.name"
                  type="text"
                  :label="$t('Name')"
                  :tooltipMessage="$t('Name')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <label for="cars"> {{ $t("Company") }} </label>
              <multi-select v-model="form.company" :options="[]" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <label for="cars"> {{ $t("Tenant") }} </label>
              <multi-select v-model="form.tenant" :options="[]" />
            </div>
            <div class="col-md-6">
              <label for="cars"> {{ $t("Roles") }} </label>
              <multi-select
                v-model="form.roles"
                :multiple="true"
                label="title"
                track-by="id"
                :options="roles"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="cars"> {{ $t("Status") }} </label>

              <select class="input-form form-control" v-model="form.status">
                <option value="1">
                  {{ $t("Active") }}
                </option>
                <option value="0">
                  {{ $t("Inactive") }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="cars"> {{ $t("Type") }} </label>

              <select class="input-form form-control" v-model="form.type">
                <option :value="1">
                  {{ $t("Owner") }}
                </option>
                <option :value="2">
                  {{ $t("Partner") }}
                </option>
                <option :value="3">
                  {{ $t("Customer") }}
                </option>
              </select>
            </div>
          </div>
          <div class="row"></div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="createAPI"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Create API Key") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapGetters("roles", ["roles"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Api Keys"),
          to: "/api-keys",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      form: {
        name: "",
        company: "",
        type: "",
        status: "",
        tenant: "",
        roles: [],
      },
      companies: [],
    };
  },

  async created() {
    await this.$store.dispatch("roles/list", {
      limit_start: 0,
      limit_count: 100,
    });
  },

  methods: {
    async createAPI() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store.dispatch("apikeys/create", {
            ...this.form,
            roles:
              this.form.roles && this.form.roles.length
                ? this.form.roles.map((role) => role.id)
                : [],
          });
          this.$router.push("/api-keys");
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
